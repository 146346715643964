import { defineComponent, computed, ref, watch, onUnmounted, getCurrentInstance } from '@vue/composition-api';
import BackButton from '@/components/BackButton.vue';
import { dateFormat } from '@/util/dates';
import SocialShare from '@/components/SocialShare.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import useStructuredData from '@/shared/composables/useStructuredData';
import { SITE_URL } from '@/App.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { useGetHowToArticleQuery } from '@/generated-types/graphql.types';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
export default defineComponent({
    components: {
        TrustPublication,
        BackButton,
        SocialShare
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const slug = computed(() => root.$route.params.slug || '');
        const article = ref(null);
        const structuredData = ref(null);
        const link = ref('');
        const preview = root.$route.query._storyblok_version === '';
        const publicationDate = ref('');
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const { onResult, refetch } = useGetHowToArticleQuery({ articleSlug: slug.value, preview }, { enabled: true });
        onResult((result) => {
            if (!result?.data?.get_how_to_article) {
                return;
            }
            const data = JSON.parse(result.data.get_how_to_article.story);
            article.value = data?.story;
            publicationDate.value = dateFormat(article.value.first_published_at || article.value.created_at, 'DD MMM YYYY');
            link.value = `${SITE_URL}/${article.value.full_slug}`;
            if (globalThis.$i18n.locale === 'en') {
                history.replaceState({}, null, link.value);
            }
            structuredData.value = {
                '@context': 'https://schema.org',
                '@type': 'HowTo',
                name: article.value?.name,
                image: [article.value?.content?.thumbnail?.filename],
                datePublished: article.value?.first_published_at,
                dateModified: article.value?.published_at,
                author: [
                    {
                        '@type': 'Person',
                        name: article.value?.content?.author
                    }
                ]
            };
            useMeta({
                title: article.value?.content?.metadata?.title || '',
                description: article.value?.content?.metadata?.description || '',
                url: link.value,
                imageUrl: article.value?.content?.thumbnail?.filename || '',
                noIndex: false
            });
            injectStructuredData(structuredData.value);
        });
        /**
         * Watch for locale change and refetch the blog article content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(article);
            instance.$on('storyPublished', refetch);
        }
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            article,
            link,
            publicationDate,
            useGetLocalizedPath
        };
    }
});
