import { render, staticRenderFns } from "./HowTo.vue?vue&type=template&id=2e04cc6a&scoped=true"
import script from "./HowTo.vue?vue&type=script&lang=ts"
export * from "./HowTo.vue?vue&type=script&lang=ts"
import style0 from "./HowTo.vue?vue&type=style&index=0&id=2e04cc6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e04cc6a",
  null
  
)

export default component.exports